import React, { useState, useEffect } from "react"
import Recaptcha from "react-recaptcha"
import { navigate } from "gatsby"
import { useMutation } from "@apollo/client"
import { FaArrowUp } from "react-icons/fa"
import { LpMexRegister } from "../crud/createRegister"
import { toast } from "react-toastify"
import axios from "axios"
import styled from "@emotion/styled"
import useSSR from "use-ssr"
import { Seo } from "../components/seo"
const LpMex = () => {
  const { isBrowser } = useSSR()
  const logoMexico = require("../images/919_mex_logo_800_retina.webp")
  const plane = require("../images/lp-mex/plane.png")
  const text_banner = require("../images/lp-mex/mv_text.png")
  const person_banner = require("../images/lp-mex/mv01.png")
  const button_red = require("../images/lp-mex/btn1.png")
  const one_1 = require("../images/lp-mex/1.png")
  const one_2 = require("../images/lp-mex/2_sp.png")
  const one_3 = require("../images/lp-mex/3_sp.png")
  const one_4 = require("../images/lp-mex/4.png")
  const one_5 = require("../images/lp-mex/5.png")
  const image_card_1 = require("../images/lp-mex/img1.jpg")
  const image_card_2 = require("../images/lp-mex/img2.jpg")
  const image_card_3 = require("../images/lp-mex/img3.jpg")
  const image_card_4 = require("../images/lp-mex/img4.jpg")
  const image_card_5 = require("../images/lp-mex/img5.jpg")
  const arrow_red = require("../images/lp-mex/arrow-down-red.png")
  const button_submit = require("../images/lp-mex/submit.png")
  // css image
  const bg_mv = require("../images/lp-mex/bg_mv.jpg")
  const bg1 = require("../images/lp-mex/bg1.jpg")
  const mv2 = require("../images/lp-mex/mv2.jpg")

  const icon1 = require("../images/lp-mex/icon1.png")
  const arrow_right = require("../images/lp-mex/arrow-right.png")
  const arrow_left = require("../images/lp-mex/arrow-left.png")
  const arrow_right_2 = require("../images/lp-mex/arrow-right-2.png")
  const arrow_left_2 = require("../images/lp-mex/arrow-left-2.png")
  const bg7 = require("../images/lp-mex/bg7.png")
  const flag = require("../images/lp-mex/flag.png")
  const SectionBanner = styled.div`
    padding: 33px 0 45px;
    background: url(${bg1});

    .title-section {
      font-size: 2.1rem;
      margin-bottom: 0.5em;
      font-family: "Roboto-Light";
      color: #bc0c23;
      position: relative;
      text-align: center;
      span {
        color: #014d35;
      }
      @media screen and (max-width: 992px) {
        font-size: 1.8rem;
        &::after {
          display: none;
        }
        &::before {
          display: none;
        }
      }
      @media screen and (max-width: 992px) {
        font-size: 1.1rem;
      }
      &::before {
        width: 193px;
        height: 6px;
        content: "";
        background: url(${icon1});
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        position: absolute;
      }
      &::after {
        width: 193px;
        height: 6px;
        content: "";
        background: url(${icon1});
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        position: absolute;
      }
    }
    .card-lpmex {
      padding: 0 5px;
      @media screen and (max-width: 992px) {
        margin-bottom: 1em;
      }
    }
    .card-lp-mex {
      border: 1px solid #014d35;
      &__header {
        padding: 0.1em 0;
        background: #014d35;
        display: flex;
        justify-content: center;
      }
      .text-card {
        font-size: 1rem;
        color: #fff;
        padding: 4px 0 2px;
        text-align: center;
        display: inline;
        justify-content: center;
        position: relative;
        &::before {
          width: 25px;
          height: 10px;
          content: "";
          background: url(${arrow_right}) no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
          top: -3px;
          bottom: 0;
          left: -30px;
          margin: auto;
          position: absolute;
        }
        &::after {
          width: 25px;
          height: 10px;
          content: "";
          background: url(${arrow_left}) no-repeat;
          -webkit-background-size: cover;
          background-size: cover;
          top: -3px;
          bottom: 0;
          right: -29px;
          margin: auto;
          position: absolute;
        }
      }
      p {
        color: #000;
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
        padding: 1.1em 1.2em;
        background: white;
        .number-value {
          margin: 0 0 0.5em 0.3em;
          font-size: 1.8rem;
          color: #bc0c23;
          span {
            color: #bc0c23;
            font-size: 1.1rem;
          }
        }
        div {
          font-size: 1.2rem;
          margin: 0.5em 0 0 0;
          text-align: center;
        }
        @media screen and (max-width: 992px) {
          padding: 1em;
          .number-value {
            font-size: 1.4rem;
          }
          div {
            font-size: 1rem;
          }
        }
        @media screen and (max-width: 520px) {
          padding: 0.5em 0.2em;
          div {
            margin: 0;
          }
        }
      }
    }
    @media screen and (max-width: 992px) {
      padding: 2em;
    }
    @media screen and (max-width: 520px) {
      padding: 0.5em 2em;
    }
  `
  const SeparatorSectionBanner = styled.div`
    background: #014d35;
    padding: 1em 0;
    display: flex;
    justify-content: center;
    position: relative;
    &::before {
      width: 25px;
      height: 25px;
      content: "";
      background: #014d35;
      bottom: -10px;
      z-index: 1;
      right: 0;
      left: 0;
      margin: auto;
      position: absolute;
      transform: rotate(45deg);
    }
    p {
      color: white;
      font-size: 1.6rem;
      letter-spacing: 2px;
      position: relative;
      &::before {
        width: 50px;
        height: 20px;
        content: "";
        background: url(${arrow_right_2}) no-repeat;
        background-size: cover;
        top: 0;
        bottom: 0;
        left: -65px;
        margin: auto;
        position: absolute;
      }
      &::after {
        width: 50px;
        height: 20px;
        content: "";
        background: url(${arrow_left_2}) no-repeat;
        background-size: cover;
        top: 0;
        bottom: 0;
        right: -65px;
        margin: auto;
        position: absolute;
      }
      @media screen and (max-width: 992px) {
        font-size: 1.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &::after {
          display: none;
        }
        &::before {
          display: none;
        }
      }
      @media screen and (max-width: 520px) {
        font-size: 0.8rem;
      }
    }
    .top-circle-lpmex {
      position: relative;
      display: inline-block;
      letter-spacing: 3px;
      margin-left: 5px;
      &:before {
        content: "";
        width: 6px;
        height: 6px;
        height: 6px;
        left: 0;
        right: 0;
        top: -5px;
        margin: auto;
        position: absolute;
        border-radius: 50%;
        background-size: cover;
        background: white;
      }
    }
    .number-five-lpmex {
      color: #fff56d;
      font-size: 3.2rem;
    }
    @media screen and (max-width: 992px) {
      padding: 0.5em 0;
    }
  `
  const FormSeparatorLpMex = styled.div`
    background: #014d35;
    padding: 1em 0;
    display: flex;
    justify-content: center;
    position: relative;
    &::before {
      width: 25px;
      height: 25px;
      content: "";
      background: #014d35;
      bottom: -10px;
      z-index: 0;
      right: 0;
      left: 0;
      margin: auto;
      position: absolute;
      transform: rotate(45deg);
    }
    h2 {
      margin: 0.5em 0;
      font-weight: normal;
      position: relative;
      color: white;
      &::before {
        width: 49px;
        height: 28px;
        content: "";
        background: url(${flag}) no-repeat;
        background-size: cover;
        top: 0;
        bottom: 0;
        left: -65px;
        margin: auto;
        position: absolute;
      }
      &::after {
        width: 49px;
        height: 28px;
        content: "";
        background: url(${flag}) no-repeat;
        background-size: cover;
        top: 0;
        bottom: 0;
        right: -65px;
        margin: auto;
        position: absolute;
      }
      span {
        font-weight: bold;
        position: relative;
        display: inline-block;
        letter-spacing: 3px;
        color: #fff56d;
        margin-left: 5px;
        &:before {
          content: "";
          width: 6px;
          height: 6px;
          height: 6px;
          left: 0;
          right: 0;
          top: -5px;
          margin: auto;
          position: absolute;
          border-radius: 50%;
          background-size: cover;
          background: #fff56d;
        }
      }
    }
    @media screen and (max-width: 992px) {
      h2 {
        font-size: 1.1rem;
        &::before {
          transform: scale(0.7);
        }
        &::after {
          transform: scale(0.7);
        }
      }
    }
    @media screen and (max-width: 520px) {
      h2 {
        font-size: 0.9rem;
        &::before {
          left: -45px;
          transform: scale(0.6);
        }
        &::after {
          right: -45px;
          transform: scale(0.6);
        }
      }
    }
  `

  const [cards] = useState([
    {
      image_number: one_1,
      text_head: "仕事が見つかりやすくて安心",
      text_underline:
        "<p><span>メキシコの</span><br/><span>日系企業求人数トップクラス</span></p>",
      description:
        "「経験を活かしてキャリアアップしたい」「転居先から●分以内」とい った希望から求人を探せます。",
      text_footer: "※バヒオ地区を中心とした日系企業求人をご用意",
      banner: image_card_1,
    },
    {
      image_number: one_2,
      text_head: "選考の進め方に悩まず安心",
      text_underline: "<p><span>フローに沿って応募</span></p>",
      description:
        "応募したい求人が決まったら、フローに沿って選考に進むだけ。企業によっては、メキシコ国内でもスカイプで面接を行う場合も。自分の予定に合わせた進め方が可能です。",
      text_footer: "",
      banner: image_card_2,
    },
    {
      image_number: one_3,
      text_head: "弊社スタッフとの事前面談で安心",
      text_underline:
        "<p><span>希望するキャリア、</span><br/><span>条件を詳しくヒアリング</span></p>",
      description:
        "これまでの経歴や、希望する働き方、エリアを詳しくヒアリング。マッチする求人をお探しします。",
      text_footer: "",
      banner: image_card_3,
    },
    {
      image_number: one_4,
      text_head: "内定が決まってからも安心",
      text_underline: "<p><span>内定後の企業とのやりとりも代行</span></p>",
      description:
        "応募～面接まではもちろん、必要に応じ条件交渉を行います。採用が決まった後も現地スタッフが企業との調整を代行。",
      text_footer: "",
      banner: image_card_4,
    },
    {
      image_number: one_5,
      text_head: "入社後も安心",
      text_underline: "<p><span>入社後のアフターフォローも万全</span></p>",
      description: "あなたが仕事に慣れるまで、私たちがしっかりサポートします。",
      text_footer: "",
      banner: image_card_5,
    },
  ])
  const handleGoTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    getIpInfo()
  })
  const [ip, setIp] = useState("No")
  const [ipAddress, setIpAddress] = useState("No")
  const getIpInfo = async () => {
    const res = await axios.get("https://geolocation-db.com/json/")
    setIp(res.data.IPv4)
    setIpAddress(res.data.country_name)
  }

  const [loading, setLoading] = useState(false)

  const [register, setRegister] = useState({
    FullName: "",
    Email: "",
    CitizenshipCountry: "",
    NativeLanguage: "-",
    Query: "",
    messageError: {},
    isVerified: false,
  })

  const emailRegex = RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
  const { messageError } = register

  const handleChange = e => {
    const { name, value } = e.target
    setRegister(prevState => ({
      ...prevState,
      [name]: value,
    }))

    let errors = register.messageError
    switch (name) {
      case "FullName":
        errors.FullName = value === "" ? "お名前は必須項目です" : ""
        break

      case "Email":
        errors.Email =
          value === ""
            ? "メールアドレスは必須項目です"
            : "" || emailRegex.test(value)
            ? ""
            : "これは有効なメールではありません"
        break
      case "CitizenshipCountry":
        errors.CitizenshipCountry = value === "" ? "国籍は必須項目です" : ""
        break
      case "NativeLanguage":
        errors.NativeLanguage = value === "" ? "母国語は必須項目です" : ""
        break
      default:
        break
    }
  }

  const [addLpMexRegister] = useMutation(LpMexRegister, {
    onCompleted: () => {
      localStorage.setItem("thankload", "1")
      navigate("/thankyou")
    },
    onError: () => {
      setLoading(false)
      toast.error("サーバーエラー")
    },
  })

  const handleRegisterLpMex = () => {
    addLpMexRegister({
      variables: {
        input: {
          data: {
            FullName: register.FullName,
            Email: register.Email,
            CitizenshipCountry: register.CitizenshipCountry,
            NativeLanguage: register.NativeLanguage,
            Query: register.Query,
            IpAddress: ip,
            IpCountry: ipAddress,
          },
        },
      },
    })
  }
  const [captchaVerify, setCaptchaVerify] = useState(false)
  const recaptchaVerify = response => {
    if (response) {
      setRegister(prevState => ({
        ...prevState,
        isVerified: true,
      }))
    } else {
      setCaptchaVerify(true)
      setTimeout(() => {
        setCaptchaVerify(false)
      }, 3000)
    }
  }
  const formValidate = (errors, ...data) => {
    let valid = true
    Object.values(errors).forEach(val => {
      val && (valid = false)
    })
    Object.values(data).forEach(val => {
      val === "" && (valid = false)
    })
    return valid
  }
  const handleSubmit = event => {
    event.preventDefault()
    // if (register.isVerified) {
    if (
      register.FullName !== "" &&
      register.Email !== "" &&
      register.CitizenshipCountry !== "" &&
      register.NativeLanguage !== ""
    ) {
      if (formValidate(register.messageError, register)) {
        setLoading(true)
        handleRegisterLpMex()
      } else {
        toast.error("フォームが正しくありません")
      }
    } else {
      toast.error("入力内容を再度ご確認ください。")
    }
    // } else {
    // recaptchaVerify()
    // }
  }
  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <Seo
        title={`メキシコ専門のお仕事紹介 - QUICK GLOBAL MEXICO`}
        description={`メキシコでのお仕事探しならクイックグローバルメキシコ。人材紹介会社クイックグローバルメキシコはお仕事に関する情報を提供しております。`}
      />
      <div className="container-full container-lp-mex">
        <div className="row">
          <div className="container">
            <div className="row container-lp-mex__head-row">
              <img src={logoMexico} alt="919 Mexico" width="200" />
              <span>東証プライム市場上場企業 QUICK Group</span>
            </div>
          </div>
        </div>
        <div className="row container-lp-mex__sub-head">
          <div className="container">
            <div className="row">
              <p>
                メキシコでの <span>就職</span> <span>転職</span>
                を検討中のみなさん <img src={plane} alt="Plane" />
              </p>
            </div>
          </div>
        </div>
        <div
          className="row container-lp-mex__banner"
          style={{ background: `url(${bg_mv}) no-repeat center` }}
        >
          <div className="container">
            <img className="banner-text" src={text_banner} alt="Text Banner" />
            <img
              className="banner-person"
              src={person_banner}
              alt="Person Banner"
            />
          </div>
        </div>
        <SectionBanner className="row container-lp-mex__section-text">
          <div className="container">
            <h2 className="title-section">
              メキシコ最大級 <span>の日系企業求人数</span>
            </h2>
            <div className="row">
              <div className="col-md-3 col-sm-6 col-6 card-lpmex">
                <div className="card-lp-mex">
                  <div className="card-lp-mex__header">
                    <h2 className="text-card">メキシコシティ</h2>
                  </div>
                  <p>
                    月給
                    <span className="number-value">
                      40,000<span>MXN</span>
                    </span>
                    <div>総務</div>
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-6 card-lpmex">
                <div className="card-lp-mex">
                  <div className="card-lp-mex__header">
                    <h2 className="text-card">ケレタロ</h2>
                  </div>
                  <p>
                    月給
                    <span className="number-value">
                      50,000<span>MXN</span>
                    </span>
                    <div>営業</div>
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-6 card-lpmex">
                <div className="card-lp-mex">
                  <div className="card-lp-mex__header">
                    <h2 className="text-card">レオン</h2>
                  </div>
                  <p>
                    月給
                    <span className="number-value">
                      60,000<span>MXN</span>
                    </span>
                    <div>セールスエンジニア</div>
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-6 card-lpmex">
                <div className="card-lp-mex">
                  <div className="card-lp-mex__header">
                    <h2 className="text-card">アグアスカリエンテス</h2>
                  </div>
                  <p>
                    月給
                    <span className="number-value">
                      35,000<span>MXN</span>
                    </span>
                    <div>通訳</div>
                  </p>
                </div>
              </div>
            </div>
            <div className="row text-underline-lp-mex">
              <p>
                <span>
                  <b>日系企業</b>での<b>正社員求人</b>を<b>多数ご用意</b>
                </span>
              </p>
            </div>
            <div className="row ">
              <a href="#form" className="button_red">
                <img src={button_red} alt="Button" />
              </a>
            </div>
          </div>
        </SectionBanner>
        <div
          className="row container-mainvisual-lp-mex"
          style={{ background: `url(${mv2}) no-repeat top` }}
        >
          <div className="container">
            <div className="only-desktop-lp-mex">
              <h2>
                求人を探せる・相談できる <br />
                メキシコ専門のお仕事紹介。
              </h2>
              <div className="text-underline-lp-mex">
                <p className="underline-text">
                  問い合わせにはすべてメキシコ在住の日本人スタッフが対応。
                </p>
              </div>
              <p className="text-description-banner">
                メキシコ国内でのキャリアアップはもちろん、ご希望条件を詳 <br />
                しくヒアリング。職場環境等含め情報提供させていただくこと <br />
                も可能です。
              </p>
            </div>
            <div className="only-smartphone-lpmex">
              <h2>
                求人を探せる・相談できる <br />
                メキシコ専門のお仕事紹介。
              </h2>
              <div className="text-underline-lp-mex">
                <p className="underline-text">
                  問い合わせにはすべてメキシコ在住の日本人スタッフが対応。
                </p>
              </div>
              <p className="text-description-banner">
                メキシコ国内でのキャリアアップはもちろん、ご希望条件を詳
                しくヒアリング。職場環境等含め情報提供させていただくこと
                も可能です。
              </p>
            </div>
          </div>
        </div>
        <SeparatorSectionBanner className="row separator-section-banner">
          <p>
            <b>メキシコ</b>の仕事探しが
            <span className="top-circle-lpmex">う</span>
            <span className="top-circle-lpmex">ま </span>
            <span className="top-circle-lpmex">く</span>
            <span className="top-circle-lpmex">い </span>
            <span className="top-circle-lpmex">く</span>
            <span className="number-five-lpmex">5</span>
            つの理由
          </p>
        </SeparatorSectionBanner>
        <div
          className="row cards-section-lpmex"
          style={{ background: `url(${bg7})` }}
        >
          <div className="container u-margin-top-3-lpmex">
            {cards.map(card => (
              <div className="cards-section-lpmex__card">
                <div className="only-texts-card">
                  <div className="cards-section-lpmex__card-top">
                    <img
                      src={card.image_number}
                      alt=""
                      className="image-number"
                    />
                    <div>
                      <p className="only-text-card">
                        <span>{card.text_head}</span>
                      </p>
                      <div
                        className="text-underline-lp-mex"
                        dangerouslySetInnerHTML={{
                          __html: card.text_underline,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="cards-section-lpmex__card-bottom">
                    <p
                      className="cards-section-lpmex__card-bottom--top-text"
                      dangerouslySetInnerHTML={{ __html: card.description }}
                    ></p>
                    <p className="cards-section-lpmex__card-bottom--bottom-text">
                      {card.text_footer}
                    </p>
                  </div>
                </div>
                <div className="image-right-lpmex">
                  <img src={card.banner} alt="" />
                </div>
              </div>
            ))}
            <div className="text-bottom-section-lpmex">
              <img src={arrow_red} alt="" />
              <p>求人だけでなく、現地の景況感や治安情報の提供も。</p>
              <div className="text-underline-lp-mex">
                <p>
                  <span>転職が半年以上先でもOK。お気軽にご利用ください。</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <FormSeparatorLpMex className="row form-separator-lpmex" id="form">
          <h2>
            <span>無</span>
            <span>料</span>
            ・メキシコ就職サポート申し込みフォーム
          </h2>
        </FormSeparatorLpMex>
        <div className="row form-lpmex">
          <form className="container" onSubmit={handleSubmit}>
            <div className="row no-gutters">
              <div
                className={`col-md-3 form-lpmex__left ${
                  messageError.FullName ? "error-field-lpmex" : ""
                }`}
              >
                <label htmlFor="お名前">
                  お名前 <span>必須</span>
                </label>
              </div>
              <div className="col-md-9 form-lpmex__right">
                {messageError.FullName && (
                  <span className="field-validation-lpmex">
                    {messageError.FullName}
                  </span>
                )}
                <input
                  type="text"
                  id="お名前"
                  name="FullName"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                <p>例）山田花子</p>
              </div>
            </div>
            <div className="row no-gutters">
              <div
                className={`col-md-3 form-lpmex__left ${
                  messageError.Email ? "error-field-lpmex" : ""
                }`}
              >
                <label htmlFor="メールアドレス">
                  メールアドレス <span>必須</span>
                </label>
              </div>
              <div className="col-md-9 form-lpmex__right">
                {messageError.Email && (
                  <span className="field-validation-lpmex">
                    {messageError.Email}
                  </span>
                )}
                <input
                  type="email"
                  id="メールアドレス"
                  name="Email"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                <p>例）xxx@yyy.zzz</p>
              </div>
            </div>
            <div className="row no-gutters">
              <div
                className={`col-md-3 form-lpmex__left ${
                  messageError.CitizenshipCountry ? "error-field-lpmex" : ""
                }`}
              >
                <label htmlFor="国籍">
                  国籍 <span>必須</span>
                </label>
              </div>
              <div className="col-md-9 form-lpmex__right">
                {messageError.CitizenshipCountry && (
                  <span className="field-validation-lpmex">
                    {messageError.CitizenshipCountry}
                  </span>
                )}
                <input
                  type="text"
                  id="国籍"
                  name="CitizenshipCountry"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                <p>例）日本</p>
              </div>
            </div>
            <div className="row no-gutters">
              <div
                className={`col-md-3 form-lpmex__left ${
                  messageError.NativeLanguage ? "error-field-lpmex" : ""
                }`}
              >
                <label htmlFor="母国語">
                  母国語 <span>必須</span>
                </label>
              </div>
              <div className="col-md-9 form-lpmex__right">
                {messageError.NativeLanguage && (
                  <span className="field-validation-lpmex">
                    {messageError.NativeLanguage}
                  </span>
                )}
                <input
                  type="text"
                  id="母国語"
                  onChange={handleChange}
                  onBlur={handleChange}
                  name="NativeLanguage"
                />
                <p>例）日本語</p>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-md-3 form-lpmex__left">
                <label htmlFor="ご希望など">
                  ご希望など <span>必須</span>
                </label>
              </div>
              <div className="col-md-9 form-lpmex__right">
                <textarea
                  rows="10"
                  cols="70"
                  name="Query"
                  id="ご希望など"
                  onChange={handleChange}
                  onBlur={handleChange}
                ></textarea>
              </div>
            </div>
            <div className="content-recaptcha-lpmex">
              <div className="captcha-style">
                {isBrowser && (
                  <Recaptcha
                    render="explicit"
                    sitekey="6Lf4VfAbAAAAAO1Fu6ZqpDdvbs929UMuN04vwA3A"
                    name="isVerify"
                    verifyCallback={recaptchaVerify}
                  />
                )}
                {captchaVerify ? (
                  <span className="field-validation-captcha">
                    Please verify that you are not a robot
                  </span>
                ) : (
                  ""
                )}
                <p>
                  当社の{" "}
                  <a
                    href="https://www.919mexico.com/termsofuse"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    利用規約・個人情報保護方針
                  </a>
                  に同意の上、お申込みください。
                </p>

                {loading === false ? (
                  <input type="image" src={button_submit} disabled={loading} alt="button-submit"/>
                ) : (
                  <i
                    className="fa fa-circle-o-notch fa-spin"
                    style={{ fontSize: "2em", opacity: "0.5" }}
                  ></i>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="footer-lpmex">
          <p>
            Copyright © QUICK GLOBAL MEXICO, S.A. DE C.V. All Rights Reserved.
          </p>
        </div>
        <button
          className="btn-goTop"
          onClick={handleGoTop}
          aria-label="Search-Top"
        >
          <FaArrowUp />
        </button>
      </div>
    </>
  )
}

export default LpMex
